import s from './style.module.css';
import { TVShowListItem } from '../TVShowListItem/TVShowListItem';
export function TVShowList({ TVShowList, onClickItem }) {
	return (
		<>
			<div className={s.title}>Vous aimerez peut-être aussi :</div>
			<div className={s.list}>
				{TVShowList.map((tvShow) => {
					return (
						<span key={tvShow.id}>
							<TVShowListItem
								tvShow={tvShow}
								onClick={onClickItem}
							/>
						</span>
					);
				})}
			</div>
		</>
	);
}

import { FiveStarRating } from '../FiveStarRating/FiveStarRating';
import s from './style.module.css';

export function TVShowDetail({ tvShow }) {
	const rating = tvShow.vote_average / 2;
	// console.log(tvShow);
	return (
		<div>
			<div className={s.title}>{tvShow.original_name}</div>
			<div className={s.rating_container}>
				<FiveStarRating rating={rating} />
				<div className={s.rating}>{rating.toFixed(2)} / 5</div>
			</div>
			<div className={s.overview + ' col-xl-6 col-lg-12'}>
				{tvShow.overview}
			</div>
		</div>
	);
}

import axios from 'axios';
import { BASE_URL, API_KEY_PARAM, LANG } from '../config';

export class TVShowAPI {
	static async fetchPopulars() {
		const response = await axios.get(
			`${BASE_URL}tv/popular${API_KEY_PARAM}${LANG}`
		);
		// console.log(response.data.results);
		return response.data.results;
	}

	static async fetchRecommendations(tvShowID) {
		const response = await axios.get(
			`${BASE_URL}tv/${tvShowID}/recommendations${API_KEY_PARAM}${LANG}`
		);
		return response.data.results;
	}

	static async fetchByTitle(title) {
		const response = await axios.get(
			`${BASE_URL}search/tv${API_KEY_PARAM}&query=${title}${LANG}`
		);
		return response.data.results;
	}

	static async fetchVideos(tvShowID) {
		const response = await axios.get(
			`${BASE_URL}tv/${tvShowID}/videos${API_KEY_PARAM}`
		);
		return response.data.results;
	}
}

import s from './style.module.css';
import { PlayBtnFill } from 'react-bootstrap-icons';
export function Videos({ VideosList }) {
	return (
		<>
			<div className={s.videos_title}>
				<PlayBtnFill /> Trailers et Vidéos
			</div>
			<div className={s.videos}>
				{console.log(VideosList)}
				{VideosList.map((video) => (
					<div key={video.id}>
						<iframe
							width='560'
							height='315'
							src={`https://www.youtube.com/embed/${video.key}`}
							title='YouTube video player'
							frameborder='0'
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
							referrerpolicy='strict-origin-when-cross-origin'
							allowfullscreen
						></iframe>
					</div>
				))}
			</div>
		</>
	);
}

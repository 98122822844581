import { useEffect, useState } from 'react';
import { TVShowAPI } from './api/tv-show';
import './global.css';
import s from './style.module.css';
import { BACK_DROP_BASE_URL } from './config';
import { TVShowDetail } from './components/TVShowDetail/TVShowDetail';
import { Logo } from './components/Logo/Logo';
import { Videos } from './components/Videos/Videos';
import logo from './assets/images/logo.png';
import { TVShowList } from './components/TVShowList/TVShowList';
import { SearchBar } from './components/SearchBar/SearchBar';

export function App() {
	const [currentTVShow, setCurrentTVShow] = useState();
	const [recommendationsList, setRecommendationsList] = useState([]);
	const [videosList, setVideosList] = useState([]);

	async function fetchPopulars() {
		const populars = await TVShowAPI.fetchPopulars();
		if (populars.length > 0) {
			setCurrentTVShow(populars[0]);
		}
	}

	async function fetchRecommendations(tvShowID) {
		const recommendations = await TVShowAPI.fetchRecommendations(tvShowID);
		if (recommendations.length > 0) {
			setRecommendationsList(recommendations.slice(0, 10));
		}
	}

	async function fetchVideos(tvShowID) {
		const videos = await TVShowAPI.fetchVideos(tvShowID);
		if (videos.length > 0) {
			setVideosList(videos.slice(0, 6));
		} else {
			setVideosList(0);
		}
	}

	useEffect(() => {
		fetchPopulars();
	}, []);

	useEffect(() => {
		if (currentTVShow) {
			fetchVideos(currentTVShow.id);
		}
	}, [currentTVShow]);

	useEffect(() => {
		if (currentTVShow) {
			fetchRecommendations(currentTVShow.id);
		}
	}, [currentTVShow]);

	// function setCurrentTVShowFromRecommendation(tvShow) {}
	async function SearchTVShow(TVShowName) {
		const searchResponse = await TVShowAPI.fetchByTitle(TVShowName);
		if (searchResponse.length > 0) {
			setCurrentTVShow(searchResponse[0]);
		}
	}
	return (
		<div
			className={s.main_container}
			style={{
				background: currentTVShow
					? `linear-gradient(rgba(0,0,0,0.55), rgba(0,0,0,0.55)), url("${BACK_DROP_BASE_URL}${currentTVShow.backdrop_path}") no-repeat center / cover`
					: 'black',
			}}
		>
			<div className={s.header}>
				<div className='row'>
					<div className='col-4'>
						<Logo
							image={logo}
							title='WatchIt'
							subtitle='Find a show you may like'
						/>
					</div>
					<div className='col-md-12 col-lg-4'>
						<SearchBar onSubmit={SearchTVShow} />
					</div>
				</div>
			</div>
			<div className={s.tv_show_detail}>
				{currentTVShow && <TVShowDetail tvShow={currentTVShow} />}
			</div>
			<div className={s.videos}>
				{currentTVShow && videosList.length > 0 && (
					<Videos VideosList={videosList} />
				)}
			</div>
			<div className={s.recommendations}>
				{recommendationsList && recommendationsList.length > 0 && (
					<TVShowList
						onClickItem={setCurrentTVShow}
						TVShowList={recommendationsList}
					/>
				)}
			</div>
		</div>
	);
}

const BASE_URL = 'https://api.themoviedb.org/3/';
const API_KEY_PARAM = '?api_key=aabd35cd510cdc83abcb0dd09520409d';
const BACK_DROP_BASE_URL = 'https://image.tmdb.org/t/p/original/';
const SMALL_IMG_COVER_BASE_URL = 'https://image.tmdb.org/t/p/w300/';
const LANG = '&language=fr-FR';
export {
	BASE_URL,
	API_KEY_PARAM,
	BACK_DROP_BASE_URL,
	SMALL_IMG_COVER_BASE_URL,
	LANG,
};
